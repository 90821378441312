import React from "react";
import { useRouter } from "next/router";
import Image from 'next/image'
import HomeHero from '../components/Organism/HomeHero'

import { Box, Heading, Link, Stack, Text } from "@chakra-ui/react";


export default function Home(props){
  return (
    <HomeHero />
  );
}