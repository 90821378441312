import React from 'react'
import dynamic from 'next/dynamic'
import { Container, AspectRatio, useBoolean } from '@chakra-ui/react';


export default function Video() {
  const videoUrl = `${process.env.NEXT_PUBLIC__HOME_VIDEO_URL}?autoplay=1&mute=1`;

  return (
    <div>
      <Container maxW="container.md">
        <AspectRatio ratio={16 / 9}>
          <iframe src={videoUrl}
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
            title='video'
          />
        </AspectRatio>
      </Container>
    </div>
  )
}